import { Header } from "semantic-ui-react";
import {
  country_url,
  delivery_boy_temp_url,
  state_url,
} from "../../../api/urls";
import BackButton from "../../../components/BackButton/BackButton";
import { useParams } from "react-router-dom";
import { useFetch } from "../../../utils/useFetch";
import { TitleLine } from "../../../components/lineTitle";
import { TableComponent } from "../../../components/TableComponent/TableComponent";
import { DashboardImage } from "../../../components/DashboardImage/DashboardImage";
import moment from "moment";
import Loading from "../../../components/Loading/Loading";
import { useModal } from "../../../components/Modaler";
import { useEffect, useState } from "react";
import { api } from "../../../api/api";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useToast } from "../../../components/Toast";
import unknownError from "../../../utils/unknownError";
import { idToname } from "../../../utils/idToname";

export function VerifyDeliveryBoy() {
  const title = "Verify user";
  const apiUrl = delivery_boy_temp_url;
  const backUrl = "/logistics/pending_registrations";
  const params = useParams();
  const id = params.id;
  const history = useHistory();
  const toast = useToast();

  const [varificationData, setVarificationdata] = useState({});

  const fetchedData = useFetch(apiUrl + id + "/");
  const convertDocumentsToArray = (fullData) => {
    const data = fullData.form_data;

    const documents = [];
    for (let i = 0; i < Object.keys(data).length; i++) {
      if (data[`documents[${i}][doc_name]`]) {
        documents.push({
          doc_file: data[`documents[${i}][doc_file]`],
          doc_name: data[`documents[${i}][doc_name]`],
          doc_type: data[`documents[${i}][doc_type]`],
          doc_expiry: data[`documents[${i}][doc_expiry]`],
          doc_number: data[`documents[${i}][doc_number]`],
          doc_section: data[`documents[${i}][doc_section]`],
        });
      }
      if (data[`documents[${i}]doc_name`]) {
        documents.push({
          doc_file: data[`documents[${i}]doc_file`],
          doc_name: data[`documents[${i}]doc_name`],
          doc_type: data[`documents[${i}]doc_type`],
          doc_expiry: data[`documents[${i}]doc_expiry`],
          doc_number: data[`documents[${i}]doc_number`],
          doc_section: data[`documents[${i}]doc_section`],
        });
      }
    }

    const newData = { ...fullData };
    newData.form_data.documents = documents;
    return newData;
  };
  const data = {
    data: fetchedData?.data?.map((d) => convertDocumentsToArray(d)) || [],
    loading: fetchedData.loading,
    refetch: fetchedData.refetch,
  };

  useEffect(() => {
    if (data.data?.[0]?.form_data) {
      setVarificationdata((v) => ({
        ...v,
        ...data.data[0].form_data,
        documents: data.data[0].form_data.documents || [],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data.loading]);

  const set = (doc_name, value) => {
    const newData = { ...varificationData };
    if (newData.documents) {
      const document = newData.documents.find((d) => d.doc_name === doc_name);
      if (document) {
        document.is_verified = value;
        setVarificationdata(newData);
      }
    }
  };

  const [saving, setSaving] = useState(false);

  if (data.loading || !data.data) {
    return <Loading />;
  }

  const allTrue = () => {
    return varificationData?.documents?.every((d) => d.is_verified);
  };

  const handleSave = async () => {
    setSaving(true);
    const d = varificationData;
    try {
      await api.patch(delivery_boy_temp_url + id + "/", {
        form_data: {
          bank: {
            bank_name: d.bank_name,
            account_holder_name: d.account_holder_name,
            account_number: d.acccount_number,
            ifsc_code: d.ifsc_code,
            upi_id_number: d.upi_id,
            verified: true,
          },
          address: {
            contact_name: "",
            contact_number: "",
            contact_email_id: "",
            building_premises_number: "",
            building_premises_name: "",
            address_line_1: d.address,
            address_line_2: "",
            landmark: d.landmark,
            district: "",
            country: d.country_address,
            country_name: "",
            city_village: d.city,
            pincode: d.pincode,
            state_link: d.state,
            state: "",
            state_code: "",
            verified: true,
          },
          user: {
            mobile_no: d.mobile_number,
            first_name: d.first_name,
            last_name: d.last_name,
            birth_date: d.dob,
            profile_img: d.photo,
            country: d.country,
            email_id: d.email_id,
          },
          documents: d.documents,
        },
        is_verified: allTrue(),
        form_status: allTrue() ? "Approval pending" : "Declined",
      });
      history.push(backUrl);
      toast.open("Success", "success");
    } catch (e) {
      if (e?.response?.status === 400 && e?.response?.data?.data) {
        toast.open(e?.response?.data.data, "success");
      } else {
        unknownError();
      }
    }
    setSaving(false);
  };
  return (
    <div>
      <BackButton href={backUrl} />
      <Header>{title}</Header>
      {/* <pre className="code">{JSON.stringify(varificationData, null, 2)}</pre> */}
      <div className="p-10" />
      <PersonalInfo data={data} />
      <div className="p-10" />
      <BankInfo data={data} />
      <div className="p-10" />
      <AddressInfo data={data} />
      <div className="p-10" />
      <UploadedDocuments data={data} vdata={varificationData} vset={set} />
      <div className="p-10" />
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div style={{ flex: 1 }} />
        <button className="btn-red" disabled={saving} onClick={handleSave}>
          Reject
        </button>
        <button
          className="btn"
          disabled={saving || !allTrue()}
          onClick={handleSave}
        >
          Accept
        </button>
      </div>
      <div className="p-10" />
    </div>
  );
}

function PersonalInfo({ data }) {
  return (
    <>
      <TitleLine right>Personal Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Image",
            renderCell: (params) => (
              <DashboardImage
                width="50px"
                height="50px"
                src={params?.row?.photo}
                circle
              />
            ),
          },
          {
            headerName: "Name",
            renderCell: (params) =>
              `${params?.row?.first_name} ${params?.row?.last_name}`,
          },
          {
            headerName: "DOB",
            renderCell: (params) =>
              moment(params?.row?.dob).format("DD/MM/YYYY"),
          },
          {
            headerName: "Vehicle",
            field: "vehicle",
          },
          {
            headerName: "Contact",
            field: "mobile_number",
          },
          {
            headerName: "Email ID",
            field: "email_id",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function BankInfo({ data }) {
  return (
    <>
      <TitleLine right>Bank Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Bank holder",
            field: "account_holder_name",
          },
          {
            headerName: "Bank name",
            field: "bank_name",
          },
          {
            headerName: "IFSC code",
            field: "ifsc_code",
          },
          {
            headerName: "Account no.",
            field: "acccount_number",
          },
          {
            headerName: "UPI ID",
            field: "upi_id",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function AddressInfo({ data }) {
  const countryData = useFetch(country_url);
  const stateData = useFetch(state_url);
  return (
    <>
      <TitleLine right>Address Info</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Address",
            field: "address",
          },
          {
            headerName: "Landmark",
            field: "landmark",
          },
          {
            headerName: "Country",
            field: "country_address",
            renderCell: (params) =>
              idToname(params.value, countryData.data) || "...",
          },
          {
            headerName: "State",
            field: "state",
            renderCell: (params) =>
              idToname(params.value, stateData.data) || "...",
          },
          {
            headerName: "City",
            field: "city",
          },
          {
            headerName: "Pincode",
            field: "pincode",
          },
        ]}
        rows={data.data.map((d) => d.form_data)}
        loading={data.loading}
      />
    </>
  );
}

function UploadedDocuments({ data, vdata, vset }) {
  const moduler = useModal();
  // const d = data?.data[0]?.form_data || {};
  return (
    <>
      <TitleLine right>Uploaded documents</TitleLine>
      <div className="p-10" />
      <TableComponent
        columns={[
          {
            headerName: "Document type",
            field: "doc_name",
          },
          {
            headerName: "Doc no.",
            field: "doc_number",
          },
          {
            headerName: "Doc expiry",
            field: "doc_expiry",
          },
          {
            headerName: "Verified ?",
            renderCell: (params) => (params.row.is_verified ? "Yes" : "No"),
          },
          {
            headerName: "File type",
            field: "doc_verified",
            renderCell: (params) => params.row.doc_file?.split(".").pop(),
          },
          {
            headerName: "Action",
            renderCell: (params) => (
              <div
                className="blue-link"
                onClick={() => {
                  moduler.opener(
                    <DocumentVerifyModal
                      fileLink={params.row.doc_file}
                      verified={params.row.is_verified}
                      onChange={(v) => {
                        vset(params.row.doc_name, v);
                      }}
                      onSave={() => moduler.closer()}
                    />,
                    true
                  );
                }}
              >
                Verify
              </div>
            ),
          },
        ]}
        rows={vdata.documents || []}
        loading={data.loading}
      />
    </>
  );
}

function DocumentVerifyModal({ fileLink, onChange, onSave }) {
  function checkURLIsImage(url) {
    return url.match(/\.(jpeg|jpg|gif|png)$/) != null;
  }

  return (
    <div style={{ padding: "20px" }}>
      <div style={{ width: "500px", height: "300px" }}>
        {checkURLIsImage(fileLink) ? (
          <DashboardImage src={fileLink} width="500px" height="300px" />
        ) : (
          <object data={fileLink} width="500px" height="300px">
            <p>
              unable to display this file, click
              <a href={fileLink} target="_blank" rel="noreferrer">
                here
              </a>
              open it.
            </p>
          </object>
        )}
      </div>
      <div className="p-10" />
      <div style={{ display: "flex", gap: "10px", alignItems: "center" }}>
        <div style={{ flex: 1 }} />
        <button
          className="btn-red"
          onClick={() => {
            onChange(false);
            onSave();
          }}
        >
          Reject
        </button>
        <button
          className="btn"
          onClick={() => {
            onChange(true);
            onSave();
          }}
        >
          Accept
        </button>
      </div>
    </div>
  );
}
