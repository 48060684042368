import { icons } from "lucide-react";
import { modules } from "../api/codes";

export const items = [
  // {
  //   title: "Dashboard",
  //   icon: icons.LayoutDashboard,
  //   path: "/dashboard",
  //   noPerm: true,
  // },
  {
    title: "Masters",
    icon: icons.LayoutPanelLeft,
    path: "/masters",
    module: modules.MasterCards,
    childrens: [
      {
        title: "Order Status",
        icon: icons.Package,
        path: "/masters/order_status",
        module: modules.OrderStatus,
      },
      {
        title: "First Category",
        icon: icons.Folder,
        path: "/masters/first_category",
        module: modules.FirstCategory,
      },
      {
        title: "Second Category",
        icon: icons.Folder,
        path: "/masters/second_category",
        module: modules.SecondCategory,
      },
      {
        title: "Third Category",
        icon: icons.Folder,
        path: "/masters/third_category",
        module: modules.ThirdCategory,
      },
      {
        title: "Cateogory Chart",
        icon: icons.PieChart,
        path: "/masters/category_chart",
        module: modules.CategoryChart,
      },
      {
        title: "Product Size",
        icon: icons.Ruler,
        path: "/masters/size",
        module: modules.ProductSize,
      },
      {
        title: "Product Color",
        icon: icons.Palette,
        path: "/masters/color",
        module: modules.ProductColor,
      },
      {
        title: "Premium Brand",
        icon: icons.Award,
        path: "/masters/premium_brand",
        module: modules.PremiumBrand,
      },
      {
        title: "Luxury Brand",
        icon: icons.Crown,
        path: "/masters/luxury_brand",
        module: modules.LuxuryBrand,
      },
      {
        title: "Property type",
        icon: icons.Settings,
        path: "/masters/property_type",
        module: modules.PropertyType,
      },
      {
        title: "Property value",
        icon: icons.Sliders,
        path: "/masters/property_value",
        module: modules.PropertyValue,
      },
      {
        title: "HSN Code",
        icon: icons.Hash,
        path: "/masters/tax_code",
        module: modules.HsnCode,
      },
      {
        title: "HSN Percent",
        icon: icons.Percent,
        path: "/masters/tax_percent",
        module: modules.HsnPercent,
      },
      {
        title: "SAC Code",
        icon: icons.Hash,
        path: "/masters/sac_tax_code",
        module: modules.SacCode,
      },
      {
        title: "SAC Percent",
        icon: icons.Percent,
        path: "/masters/sac_tax_percent",
        module: modules.SacPercent,
      },
      {
        title: "Charge name",
        icon: icons.Tag,
        path: "/masters/charge_name",
        module: modules.ChargeName,
      },
      {
        title: "Reels",
        icon: icons.Film,
        path: "/masters/reels",
        module: modules.Reel,
      },
      {
        title: "Product Questions",
        icon: icons.HelpCircle,
        path: "/masters/product_questions",
        module: modules.ProductQuestion,
      },
      {
        title: "Product Info",
        icon: icons.Info,
        path: "/masters/product_info",
        module: modules.ProductInfo,
      },
      {
        title: "Sort List",
        icon: icons.List,
        path: "/masters/sort_list",
        module: modules.SortList,
      },
      {
        title: "Logistics Partner",
        icon: icons.Truck,
        path: "/masters/logistics_partner",
        module: modules.LogisticPartner,
      },
      {
        title: "Continents",
        icon: icons.Globe,
        path: "/masters/continents",
        module: modules.Continent,
      },
      {
        title: "Country",
        icon: icons.Flag,
        path: "/masters/country",
        module: modules.Country,
      },
      {
        title: "Regions",
        icon: icons.MapPin,
        path: "/masters/regions",
        module: modules.Region,
      },
      {
        title: "State",
        icon: icons.MapPin,
        path: "/masters/state",
        module: modules.State,
      },
      {
        title: "District",
        icon: icons.MapPin,
        path: "/masters/district",
        module: modules.District,
      },
      {
        title: "Taluka",
        icon: icons.MapPin,
        path: "/masters/taluka",
        module: modules.Taluka,
      },
      {
        title: "City",
        icon: icons.MapPin,
        path: "/masters/city",
        module: modules.City,
      },
      {
        title: "Village",
        icon: icons.MapPin,
        path: "/masters/village",
        module: modules.Village,
      },
      {
        title: "Pincode",
        icon: icons.MapPin,
        path: "/masters/pincode",
        module: modules.Pincode,
      },
      {
        title: "Area",
        icon: icons.MapPin,
        path: "/masters/area",
        module: modules.Area,
      },
      {
        title: "Annual Turnover",
        icon: icons.TrendingUp,
        path: "/masters/annual_turnover",
        module: modules.AnnualTurnover,
      },
      {
        title: "Business Type",
        icon: icons.Briefcase,
        path: "/masters/business_type",
        module: modules.BusinessType,
      },
      {
        title: "Organisation Type",
        icon: icons.Users,
        path: "/masters/organisation_type",
        module: modules.OrganisationType,
      },
      {
        title: "Facility Type",
        icon: icons.Building2,
        path: "/masters/facility_type",
        module: modules.FacilityType,
      },
      {
        title: "Document Type",
        icon: icons.FileText,
        path: "/masters/kyc_document",
        module: modules.DocumentType,
      },
      {
        title: "Certificate Type",
        icon: icons.Award,
        path: "/masters/kyc_certificate",
        module: modules.CertificateType,
      },
      {
        title: "Roles",
        icon: icons.UserCheck,
        path: "/masters/roles",
        module: modules.Role,
      },
      {
        title: "Size Chart Column",
        icon: icons.Table,
        path: "/masters/size_chart_column",
        module: modules.SizeChartColumn,
      },
      {
        title: "Marital Status",
        icon: icons.Heart,
        path: "/masters/marital_status",
        module: modules.MartialStatus,
      },
      {
        title: "Title",
        icon: icons.Type,
        path: "/masters/title",
        module: modules.Title,
      },
      {
        title: "Payment Gateway",
        icon: icons.CreditCard,
        path: "/masters/payment_gateway",
        module: modules.PaymentGateway,
      },
      {
        title: "Notifications",
        icon: icons.Bell,
        path: "/masters/notifications",
        module: modules.Notification,
      },
      {
        title: "Widget Title",
        icon: icons.Type,
        path: "/masters/widget_title",
        module: modules.WidgetTitle,
      },
      {
        title: "Configuration",
        icon: icons.Settings,
        path: "/masters/configuration",
        module: modules.Configuration,
      },
      {
        title: "Branch",
        icon: icons.GitBranch,
        path: "/masters/branch",
        module: modules.Branch,
      },
      {
        title: "Department",
        icon: icons.Building,
        path: "/masters/department",
        module: modules.Department,
      },
      {
        title: "Designation",
        icon: icons.UserPlus,
        path: "/masters/designation",
        module: modules.Designation,
      },
      {
        title: "B2B Verification Fields",
        icon: icons.Clipboard,
        path: "/masters/b2b_verification_fields",
        module: modules.B2BVerificationFeilds,
      },
      {
        title: "B2B Verification Sections",
        icon: icons.Layers,
        path: "/masters/b2b_verification_sections",
        module: modules.B2BVerificationSections,
      },
      {
        title: "Delivery Boy Fields",
        icon: icons.Clipboard,
        path: "/masters/delivery_boy_fields",
        module: modules.DeliveryBoyFeilds,
      },
      {
        title: "Delivery Boy Sections",
        icon: icons.Layers,
        path: "/masters/delivery_boy_sections",
        module: modules.DeliveryBoySections,
      },
      {
        title: "Seller Subject Category",
        icon: icons.Folder,
        path: "/masters/seller_subject_category",
        module: modules.SellerSubjectCategory,
      },
      {
        title: "Seller Subject Sub Category",
        icon: icons.Folder,
        path: "/masters/seller_subject_sub_category",
        module: modules.SellerSubjectSubCategory,
      },
      {
        title: "Seller Fields",
        icon: icons.Clipboard,
        path: "/masters/seller_fields",
        module: modules.SellerFeilds,
      },
      {
        title: "Seller Sections",
        icon: icons.Layers,
        path: "/masters/seller_sections",
        module: modules.SellerSections,
      },
      {
        title: "Ticket Subject",
        icon: icons.Ticket,
        path: "/masters/ticket_subject",
        module: modules.TicketSubject,
      },
    ],
  },
  {
    title: "Orders",
    icon: icons.ShoppingCart,
    path: "/orders",
    module: modules.Order,
  },
  {
    title: "Products",
    icon: icons.Package,
    path: "/products",
    module: modules.Product,
  },
  {
    title: "Global Store",
    icon: icons.Globe,
    path: "/global_store",
    module: modules.GlobalCards,
    childrens: [
      {
        title: "Products",
        icon: icons.Package,
        path: "/global_store/products",
        module: modules.GlobalProduct,
      },
      {
        title: "Luxury Brand",
        icon: icons.Crown,
        path: "/global_store/luxury_brand",
        module: modules.GlobalLuxuryBrand,
      },
      {
        title: "Premium Brand",
        icon: icons.Award,
        path: "/global_store/premium_brand",
        module: modules.GlobalPremiumBrand,
      },
      {
        title: "First Category",
        icon: icons.Folder,
        path: "/global_store/first_category",
        module: modules.GlobalCategoryOne,
      },
      {
        title: "Second Category",
        icon: icons.Folder,
        path: "/global_store/second_category",
        module: modules.GlobalCategoryTwo,
      },
      {
        title: "Third Category",
        icon: icons.Folder,
        path: "/global_store/third_category",
        module: modules.GlobalCategoryThree,
      },
    ],
  },
  {
    title: "B2B Store",
    icon: icons.Briefcase,
    path: "/b2b_store",
    module: modules.B2BCards,
    childrens: [
      {
        title: "Eligible Users",
        icon: icons.UserCheck,
        path: "/b2b_store/eligible_users",
        module: modules.B2BEligibleUsers,
        noPerm: true,
      },
      {
        title: "B2B Verification",
        icon: icons.CheckSquare,
        path: "/b2b_store/b2b_verification",
        module: modules.B2BVerification,
        noPerm: true,
      },
      {
        title: "Products",
        icon: icons.Package,
        path: "/b2b_store/products",
        module: modules.B2BProduct,
      },
      {
        title: "First Category",
        icon: icons.Folder,
        path: "/b2b_store/first_category",
        module: modules.B2BCategoryOne,
      },
      {
        title: "Second Category",
        icon: icons.Folder,
        path: "/b2b_store/second_category",
        module: modules.B2BCategoryTwo,
      },
      {
        title: "Third Category",
        icon: icons.Folder,
        path: "/b2b_store/third_category",
        module: modules.B2BCategoryThree,
      },
    ],
  },
  {
    title: "Users",
    icon: icons.Users,
    path: "/users",
    module: modules.UserCards,
    childrens: [
      {
        title: "Internal users",
        icon: icons.UserCog,
        path: "/users/internal_users",
        module: modules.InternalUser,
      },
      {
        title: "Tagon customers",
        icon: icons.User,
        path: "/users/tagon_customers",
        module: modules.TagonCustomer,
      },
    ],
  },
  {
    title: "Sellers",
    icon: icons.Store,
    path: "/sellers",
    module: modules.SellerCards,
    childrens: [
      {
        title: "Sellers",
        icon: icons.Users,
        path: "/sellers/sellers",
        module: modules.Seller,
      },
      {
        title: "Profile KYC",
        icon: icons.FileCheck,
        path: "/sellers/profile_kyc",
        module: modules.ProfileKYC,
      },
      {
        title: "Other KYC",
        icon: icons.FileCheck,
        path: "/sellers/other_kyc",
        module: modules.OtherKYC,
      },
    ],
  },
  {
    title: "Manage Tickets",
    icon: icons.Ticket,
    path: "/manage_tickets",
    module: modules.ManageTickets,
  },
  {
    title: "Delivery",
    icon: icons.Truck,
    path: "/logistics",
    module: modules.DeliveryCards,
    childrens: [
      {
        title: "Assigned Orders",
        icon: icons.Clipboard,
        path: "/logistics/assigned_orders",
        module: modules.AssignedOrders,
      },
      {
        title: "Registered Users",
        icon: icons.UserCheck,
        path: "/logistics/pls",
        module: modules.RegisteredUsers,
      },
      {
        title: "Pending Registrations",
        icon: icons.UserPlus,
        path: "/logistics/pending_registrations",
        module: modules.PendingRegistrations,
      },
      {
        title: "Privacy Policy",
        icon: icons.Shield,
        path: "/logistics/pls_privacy",
        module: modules.DeliveryPrivacy,
      },
      {
        title: "FAQ",
        icon: icons.HelpCircle,
        path: "/logistics/pls_faq",
        module: modules.DeliveryFaq,
      },
      {
        title: "Delivered To Options",
        icon: icons.CheckSquare,
        path: "/logistics/delivered_to",
        module: modules.DeliveryChoice,
      },
      {
        title: "Terms & Conditions",
        icon: icons.FileText,
        path: "/logistics/pls_terms",
        module: modules.DeliveryTerms,
      },
    ],
  },
  {
    title: "Coupons",
    icon: icons.Tag,
    path: "/coupons",
    module: modules.Coupon,
  },
  {
    title: "Advertisements",
    icon: icons.Image,
    path: "/advertisements",
    module: modules.Advertisement,
  },
  {
    title: "Policy",
    icon: icons.FileText,
    path: "/policy",
    module: modules.PolicyCards,
    childrens: [
      {
        title: "Policy Title",
        icon: icons.Type,
        path: "/policy/policy_title",
        module: modules.PolicyTitle,
      },
      {
        title: "Policy Content",
        icon: icons.FileText,
        path: "/policy/policy_content",
        module: modules.PolicyContent,
      },
    ],
  },
  {
    title: "Help center",
    icon: icons.HelpCircle,
    path: "/help_center",
    module: modules.HelpCenterCards,
    childrens: [
      {
        title: "Customer Complaints",
        icon: icons.MessageSquare,
        path: "/help_center/helpline_forms",
        module: modules.HelplineForm,
      },
      {
        title: "Helpline number",
        icon: icons.Phone,
        path: "/help_center/helpline_number",
        module: modules.HelplineNumber,
      },
      {
        title: "FAQ's title",
        icon: icons.HelpCircle,
        path: "/help_center/faqs_title",
        module: modules.FaqTitle,
      },
      {
        title: "FAQ's content",
        icon: icons.FileText,
        path: "/help_center/faqs_content",
        module: modules.FaqContent,
      },
    ],
  },
  {
    title: "Horoscope",
    icon: icons.Star,
    path: "/horoscope",
    module: modules.Horoscope,
  },
  {
    title: "Logs",
    icon: icons.FileText,
    path: "/logs",
    module: modules.LogCards,
    childrens: [
      {
        title: "ERP logs",
        icon: icons.FileText,
        path: "/logs/erp_logs",
        module: modules.ERPLogs,
      },
      {
        title: "Reward logs",
        icon: icons.Award,
        path: "/logs/reward_logs",
        module: modules.RewardLog,
      },
      {
        title: "Payment logs",
        icon: icons.CreditCard,
        path: "/logs/payment_logs",
        module: modules.PaymentLog,
      },
      {
        title: "Order logs",
        icon: icons.ShoppingCart,
        path: "/logs/order_logs",
        module: modules.OrderLog,
      },
      {
        title: "FCM Response",
        icon: icons.Bell,
        path: "/logs/fcm_response",
        module: modules.FcmResponse,
      },
      {
        title: "PG Response",
        icon: icons.FileText,
        path: "/logs/pg_response",
        module: modules.PgResponse,
      },
      {
        title: "SMS Response",
        icon: icons.MessageSquare,
        path: "/logs/sms_response",
        module: modules.SmsResponse,
      },
      {
        title: "PG Webhook",
        icon: icons.Webhook,
        path: "/logs/pg_webhook",
        module: modules.PgWebhook,
      },
      {
        title: "Contact Us",
        icon: icons.Mail,
        path: "/logs/contact_us",
        module: modules.ContactUs,
      },
    ],
  },
];
