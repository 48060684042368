import { modules, perms } from "../../api/codes";
import ManageTickets from "../../pages/manage_tickets/manage_tickets";
import NewTicket from "../../pages/manage_tickets/new_ticket";
import ViewTicket from "../../pages/manage_tickets/view_ticket";
import { ProtectedElement } from "../ProtectedElement/ProtectedElement";

export const RouteTicketing = [
  {
    path: "/manage_tickets",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.viewList} page>
        <ManageTickets />
      </ProtectedElement>
    ),
  },
  {
    path: "/manage_tickets/new",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.add} page>
        <NewTicket />
      </ProtectedElement>
    ),
  },
  {
    path: "/manage_tickets/view/:id",
    content: (
      <ProtectedElement module={modules.Horoscope} perm={perms.view} page>
        <ViewTicket />
      </ProtectedElement>
    ),
  },
  //   {
  //     path: "/manage_tickets/new",
  //     content: (
  //       <ProtectedElement module={modules.Horoscope} perm={perms.add} page>
  //         <NewHoroscopeTitle />
  //       </ProtectedElement>
  //     ),
  //   },
  //   {
  //     path: "/horoscope/edit/:id",
  //     content: (
  //       <ProtectedElement module={modules.Horoscope} perm={perms.edit} page>
  //         <NewHoroscopeTitle edit />
  //       </ProtectedElement>
  //     ),
  //   },
  //   {
  //     path: "/horoscope/view/:id",
  //     content: (
  //       <ProtectedElement module={modules.Horoscope} perm={perms.view} page>
  //         <NewHoroscopeTitle view />
  //       </ProtectedElement>
  //     ),
  //   },
];
