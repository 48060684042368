import { useCallback, useEffect, useRef, useState } from "react";
import "./Inputs.scss";
import { Input } from "semantic-ui-react";
import { ReactComponent as ClockIcon } from "../../images/clock.svg";
import Loading from "../Loading/Loading";
import { useDropzone } from "react-dropzone";
import { DashboardImage } from "../DashboardImage/DashboardImage";
import _ from "lodash";

export const InputText = ({
  required,
  label,
  placeholder,
  value,
  onChange,
  type,
  icon,
  pattern,
  patternError,
  error,
  disabled,
  maxLength,
  ...props
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setV(value);
    } else {
      setV("");
    }
  }, [value, setV]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  return (
    <div className={"input-text " + (disabled ? "disabled" : "")}>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
      <input
        id={label}
        type={type ?? "text"}
        placeholder={placeholder}
        required={required}
        value={v}
        disabled={disabled}
        autoComplete="on"
        pattern={pattern}
        title={patternError}
        maxLength={maxLength}
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
        onChange={(e) => {
          e.preventDefault();
          setV(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
          if (!!errorMessage) {
            setErrorMessage(e.target.validationMessage);
          }
        }}
        {...props}
      />
      <label>
        {label} {required && label ? "*" : ""}
      </label>
      {icon}
    </div>
  );
};

export const InputDate = ({
  required,
  label,
  placeholder,
  type,
  value,
  onChange,
  icon,
  error,
  disabled,
  noTime,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (value !== undefined && value !== null) {
      let tmp = value;
      if (tmp.includes("T") && !type && !noTime) {
        tmp = tmp.slice(0, tmp.indexOf("T"));
      }
      setV(tmp);
    }
  }, [value, setV, type, noTime]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  return (
    <div>
      <div
        className={
          "input-date " +
          (!v ? "input-date-empty " : "") +
          (disabled ? "disabled" : "")
        }
      >
        {/* <div
          className="fake-input-date"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            ref.current.showPicker();
          }}
        >
          {v ? (
            <div>{moment(v).format("DD-MM-YYYY")}</div>
          ) : (
            <div className="placeholder">{label}</div>
          )}
          <DatePicker height={15} width={15} />
        </div> */}
        <input
          id={label}
          ref={ref}
          type={type ? type : "date"}
          // style={{
          //   position: "relative",
          //   zIndex: -100,
          // }}
          placeholder={placeholder}
          required={required}
          value={v}
          disabled={disabled}
          autoComplete="on"
          onInvalid={(e) => {
            e.preventDefault();
            setErrorMessage(e.target.validationMessage);
          }}
          onChange={(e) => {
            setV(e.target.value);
            if (onChange) {
              if (type) {
                onChange(e.target.value);
              } else {
                if (!noTime) {
                  onChange(e.target.value + "T00:00");
                } else {
                  onChange(e.target.value);
                }
              }
            }
            if (!!errorMessage) {
              setErrorMessage(e.target.validationMessage);
            }
          }}
        />
        <label htmlFor={label}>
          {label} {required && label ? "*" : ""}
        </label>
        {icon}
      </div>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export const InputTime = ({
  required,
  label,
  placeholder,
  value,
  onChange,
  icon,
  error,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (value !== undefined && value !== null) {
      setV(value);
    }
  }, [value, setV]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  return (
    <>
      <div
        className={
          "input-date " +
          (!v ? "input-date-empty " : "") +
          (disabled ? "disabled" : "")
        }
      >
        {false && (
          <div
            className="fake-input-date"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              ref.current.showPicker();
            }}
          >
            {v ? <div>{v}</div> : <div className="placeholder">{label}</div>}
            <ClockIcon height={15} width={15} />
          </div>
        )}
        <input
          id={label}
          ref={ref}
          type="time"
          style={
            {
              // position: "relative",
              // zIndex: -100,
            }
          }
          placeholder={placeholder}
          required={required}
          value={v}
          disabled={disabled}
          autoComplete="on"
          onInvalid={(e) => {
            e.preventDefault();
            setErrorMessage(e.target.validationMessage);
          }}
          onChange={(e) => {
            setV(e.target.value);
            if (onChange) {
              onChange(e.target.value);
            }
            if (!!errorMessage) {
              setErrorMessage(e.target.validationMessage);
            }
          }}
        />
        <label htmlFor={label}>
          {label} {required && label ? "*" : ""}
        </label>
        {icon}
      </div>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </>
  );
};

export const InputNumber = ({
  error,
  label,
  placeholder,
  value,
  onChange,
  required,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useEffect(() => {
    if (value !== undefined) {
      setV(value);
    }
  }, [value, setV]);

  return (
    <div className={"input-text " + (disabled ? "disabled" : "")}>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
      <input
        id={label}
        placeholder={placeholder}
        type="number"
        value={v}
        disabled={disabled}
        required={required}
        autoComplete="on"
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
        onChange={(e) => {
          if (e.target.value) {
            setV(Number(e.target.value));
            if (onChange) {
              onChange(Number(e.target.value));
            }
          } else {
            setV(e.target.value);
            if (onChange) {
              onChange(e.target.value);
            }
          }
          if (!!errorMessage) {
            setErrorMessage(e.target.validationMessage);
          }
        }}
      />
      <label htmlFor={label}>
        {label} {required && label ? "*" : ""}
      </label>
    </div>
  );
};

export const InputTextBox = ({
  label,
  placeholder,
  value,
  onChange,
  required,
  error,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [v, setV] = useState("");

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useEffect(() => {
    if (value !== undefined) {
      setV(value);
    }
  }, [value, setV]);

  return (
    <div className={"input-text-box " + (disabled ? "disabled" : "")}>
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
      <textarea
        id={label}
        rows={5}
        type="text"
        autoComplete="on"
        placeholder={placeholder}
        value={v}
        disabled={disabled}
        required={required}
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
        onChange={(e) => {
          setV(e.target.value);
          if (onChange) {
            onChange(e.target.value);
          }
          if (!!errorMessage) {
            setErrorMessage(e.target.validationMessage);
          }
        }}
      />
      <label htmlFor={label}>
        {label} {required && label ? "*" : ""}
      </label>
    </div>
  );
};

export const InputCheckBox = ({
  label,
  value,
  onChange,
  color,
  disabled,
  labelColor,
}) => {
  const [checked, setChecked] = useState(false);
  useEffect(() => {
    if (value !== undefined) {
      setChecked(value);
    }
  }, [value, setChecked]);
  return (
    <div className={"input-check " + (disabled ? "disabled" : "")}>
      <input
        style={color ? { accentColor: color } : null}
        id={label}
        type="checkbox"
        checked={value}
        autoComplete="on"
        disabled={disabled}
        onClick={(e) => e.stopPropagation()}
        onChange={(e) => {
          if (onChange) {
            onChange(!checked);
          }
          setChecked(!checked);
        }}
      />
      <label
        htmlFor={label}
        onClick={(e) => e.stopPropagation()}
        style={{ color: labelColor ? labelColor : null }}
      >
        {label}
      </label>
    </div>
  );
};

export const InputToggle = ({ label, value, onChange, disabled }) => {
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (value !== undefined) {
      setChecked(value);
    }
  }, [value, setChecked]);

  return (
    <div className={"toggle-wrapper " + (disabled ? "disabled" : "")}>
      <label htmlFor={label} className="toggle-label">
        {label}
      </label>
      <label className="toggle-switch">
        <input
          id={label}
          type="checkbox"
          checked={checked}
          autoComplete="on"
          disabled={disabled}
          onChange={(e) => {
            if (onChange) {
              onChange(!checked);
            }
            setChecked(!checked);
          }}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
};

export const InputSelect = ({
  label,
  placeholder,
  options,
  value,
  onChange,
  required,
  error,
  disabled,
  noSpacer,
  fixedPosition,
  noSearch,
  loading,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [v, setV] = useState("");
  const [search, setSearch] = useState("");

  useEffect(() => {
    if (value !== undefined) {
      setV(value);
    }
  }, [value]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  const optionsRef = useRef();
  const ref = useRef();
  useOutsideClick(ref, () => {
    setOpen(false);
  });
  useOutsideScroll(ref, () => {
    if (fixedPosition) {
      setOpen(false);
    }
  });
  useEffect(() => {
    if (onChange && v !== "") {
      onChange(v);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v]);

  let top = 0;
  let right = 0;
  let width = 0;
  if (ref.current && fixedPosition) {
    if (optionsRef.current) {
      const height = optionsRef.current.getBoundingClientRect().height;
      top = ref.current.getBoundingClientRect().bottom + 3;
      const left = ref.current.getBoundingClientRect().right + 3;
      const width = optionsRef.current.getBoundingClientRect().width + 3;
      const widthTrig = ref.current.getBoundingClientRect().width + 3;
      right = window.innerWidth - ref.current.getBoundingClientRect().right;
      if (window.innerHeight - top < height) {
        top -= height + 27;
      }
      if (left < width) {
        right -= width - widthTrig;
      }
    }
    width = ref.current.getBoundingClientRect().width;
  }

  return (
    <>
      <div
        className={
          "select-box" +
          (v === "" ? " placeholder-shown " : "") +
          (disabled ? " disabled" : "")
        }
        ref={ref}
      >
        <label htmlFor={label}>
          {label} {required && label ? "*" : ""}
        </label>
        {!noSpacer && <div className={"spacer" + (open ? " active" : "")} />}
        <div
          ref={optionsRef}
          className={"options-container" + (open ? " active" : "")}
          style={
            fixedPosition
              ? { position: "fixed", top: top, right: right, width: width }
              : null
          }
        >
          <div className="option hidden">
            <input
              type="radio"
              className="radio"
              autoComplete="on"
              id={label}
              name={label}
              required={required && v === ""}
              onInvalid={(e) => {
                e.preventDefault();
                setErrorMessage(e.target.validationMessage);
              }}
              onChange={(e) => {
                if (!!errorMessage) {
                  setErrorMessage(e.target.validationMessage);
                }
              }}
            />
          </div>

          {!noSearch && (
            <div className="search-input">
              <Input
                type="text"
                placeholder="Search"
                icon="search"
                fluid
                value={search}
                autoComplete="on"
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          )}

          <div className="options">
            {options && options?.length > 0 ? (
              options
                .filter((o) => o.toLowerCase().includes(search.toLowerCase()))
                .map((o, idx) => {
                  return (
                    <div
                      key={idx}
                      className="option"
                      onClick={() => {
                        setOpen(false);
                        setV(o);
                      }}
                    >
                      {o}
                    </div>
                  );
                })
            ) : loading || loading === undefined ? (
              <div style={{ scale: "0.7" }}>
                <Loading />
              </div>
            ) : (
              <div style={{ padding: "3px" }}>No data available</div>
            )}
          </div>
        </div>

        <div
          className={"box-selected" + (v === "" ? " placeholder-shown" : "")}
          onClick={() => {
            if (!disabled) {
              setOpen((v) => !v);
            }
          }}
        >
          {v === "" ? placeholder : <span>{v}</span>}
          {!disabled && v && (
            <span
              className="box-selected-item"
              style={{
                fontSize: "10px",
                marginLeft: "auto",
                marginRight: "25px",
                width: "22px",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
              onClick={(e) => {
                e.stopPropagation();
                if (!disabled) {
                  setV("");
                  onChange("");
                }
              }}
            >
              &#10005;
            </span>
          )}
        </div>
        {!!errorMessage && v === "" && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
    </>
  );
};

export const InputSelectMultiple = ({
  label,
  placeholder,
  options,
  onChange,
  required,
  disabled,
  value,
  error,
  onDelete,
  displayAs,
  loading,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [open, setOpen] = useState(false);
  const [values, setValues] = useState(undefined);
  const [search, setSearch] = useState("");
  const ref = useRef();

  useEffect(() => {
    if (value !== undefined) {
      setValues(value);
    }
  }, [value, setValues]);

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useOutsideClick(ref, () => {
    setOpen(false);
  });

  const addValue = (v) => {
    if (values === undefined) {
      setValues([v]);
      if (onChange) {
        onChange([v]);
      }
      return;
    }
    if (!AlreadyAdded(v)) {
      setValues([...values, v]);
      if (onChange) {
        onChange([...values, v]);
      }
    }
  };

  const AlreadyAdded = (v) => {
    if (values === undefined) return false;
    return values.findIndex((x) => x === v) !== -1;
  };

  const removeValue = (v) => {
    if (values) {
      const newValues = values.filter((x) => x !== v);
      if (onDelete) onDelete(v);
      if (newValues.length === 0) {
        setValues(undefined);
        if (onChange) {
          onChange(undefined);
        }
      } else {
        setValues(newValues);
        if (onChange) {
          onChange(newValues);
        }
      }
    }
  };

  const addAll = (v) => {
    setValues(options);
    if (onChange) {
      onChange(options);
    }
  };

  return (
    <>
      <div
        className={
          "select-box" +
          (values === undefined ? " placeholder-shown" : "") +
          (disabled ? " disabled" : "")
        }
        ref={ref}
      >
        <label htmlFor={label}>
          {label} {required && label ? "*" : ""}
        </label>
        <div className={"spacer" + (open ? " active" : "")} />
        <div className={"options-container-multi" + (open ? " active" : "")}>
          <div className="option hidden">
            <input
              type="radio"
              className="radio"
              id={label}
              name={label}
              autoComplete="on"
              required={required && values === undefined}
              onInvalid={(e) => {
                e.preventDefault();
                setErrorMessage(e.target.validationMessage);
              }}
              onChange={(e) => {
                if (!!errorMessage) {
                  setErrorMessage(e.target.validationMessage);
                }
              }}
            />
          </div>
          <div className="search-input">
            <Input
              type="text"
              placeholder="Search"
              icon="search"
              fluid
              value={search}
              autoComplete="on"
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
          <div className="select-all" onClick={() => addAll()}>
            Select all
          </div>
          <div className="options">
            {options && options?.length > 0 ? (
              options
                .filter((o) => o.toLowerCase().startsWith(search.toLowerCase()))
                .map((o, idx) => {
                  return (
                    <div
                      key={idx}
                      className="option"
                      onClick={() => {
                        setErrorMessage("");
                        if (AlreadyAdded(o)) {
                          removeValue(o);
                        } else {
                          addValue(o);
                        }
                      }}
                    >
                      <input type="radio" className="radio" autoComplete="on" />
                      <input
                        type="checkbox"
                        checked={AlreadyAdded(o)}
                        readOnly
                        autoComplete="on"
                      />
                      {displayAs ? displayAs(o) : o}
                    </div>
                  );
                })
            ) : loading || loading === undefined ? (
              <div style={{ scale: "0.7" }}>
                <Loading />
              </div>
            ) : (
              <div style={{ padding: "3px" }}>No data available</div>
            )}
          </div>
        </div>

        <div
          className={
            "box-selected" + (values === undefined ? " placeholder-shown" : "")
          }
          onClick={() => {
            if (!disabled) setOpen((v) => !v);
          }}
        >
          {values === undefined
            ? placeholder
            : values.map((v, idx) => {
                return (
                  <span
                    className="box-selected-item"
                    key={idx}
                    onClick={(e) => {
                      e.stopPropagation();
                      if (!disabled) {
                        removeValue(v);
                      }
                    }}
                  >
                    {v} <span style={{ fontSize: "10px" }}>&#10005;</span>
                  </span>
                );
              })}
        </div>
        {!!errorMessage && values === undefined && (
          <div className="error-message">{errorMessage}</div>
        )}
      </div>
    </>
  );
};

export const InputFile = ({
  label,
  value,
  onChange,
  required,
  error,
  disabled,
  accept,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const ref = useRef();

  const onDrop = useCallback((acceptedFiles, _, e) => {
    setSelectedFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    noClick: true,
  });

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  // create a preview as a side effect, whenever selected file is changed
  // useEffect(() => {
  //   if (!selectedFile) {
  //     return;
  //   }

  //   const objectUrl = URL.createObjectURL(selectedFile);

  //   // free memory when ever this component is unmounted
  //   return () => URL.revokeObjectURL(objectUrl);
  // }, [selectedFile]);

  useEffect(() => {
    const getFile = async () => {
      if (value && ref.current) {
        const dataTransfer = new DataTransfer();
        if (_.isString(value)) {
          // dataTransfer.items.add(await urlTofile(value));
          // ref.current.files = dataTransfer.files;
          setSelectedFile(value);
        } else {
          dataTransfer.items.add(value);
          ref.current.files = dataTransfer.files;
          setSelectedFile(value);
        }
      }
    };
    getFile();
  }, [value, setSelectedFile]);

  const onSelectFile = (e) => {
    if (onChange) {
      onChange(e.target.files[0]);
    }
    if (!!errorMessage) {
      setErrorMessage(e.target.validationMessage);
    }
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
  };

  return (
    <div
      {...getRootProps()}
      className={
        "input-image input-file" +
        (selectedFile ? "" : " empty") +
        (disabled ? " disabled" : "")
      }
    >
      <label className="label">
        {label} {required && label ? "*" : ""}
      </label>
      <div className="upload-image" onClick={() => ref.current.click()}>
        {selectedFile && (
          <div className="file-name wrap">{selectedFile.name}</div>
        )}
        <div className="intruction">
          {selectedFile ? "Change File" : "Choose File"}
        </div>
      </div>
      <input
        {...getInputProps()}
        id={label}
        type="file"
        ref={ref}
        required={required}
        autoComplete="on"
        onChange={onSelectFile}
        disabled={disabled}
        accept={accept}
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
      />
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export const InputImage = ({
  label,
  value,
  onChange,
  required,
  error,
  disabled,
}) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [selectedFile, setSelectedFile] = useState();
  const [preview, setPreview] = useState();
  const ref = useRef();

  const onDrop = useCallback((acceptedFiles, _, e) => {
    setSelectedFile(acceptedFiles[0]);
    onChange(acceptedFiles[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: onDrop,
    noClick: true,
  });

  useEffect(() => {
    setErrorMessage((v) => {
      if (!error) {
        return v;
      } else {
        return error;
      }
    });
  }, [error, setErrorMessage]);

  useEffect(() => {
    const getFile = async () => {
      if (value && ref.current) {
        const dataTransfer = new DataTransfer();
        if (_.isString(value)) {
          // dataTransfer.items.add(await urlTofile(value));
          // ref.current.files = dataTransfer.files;
          setSelectedFile(value);
        } else {
          dataTransfer.items.add(value);
          ref.current.files = dataTransfer.files;
          setSelectedFile(value);
        }
      }
    };
    getFile();
  }, [value, setSelectedFile]);
  // create a preview as a side effect, whenever selected file is changed
  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined);
      ref.current.value = "";
      return;
    }

    const objectUrl = _.isString(selectedFile)
      ? selectedFile
      : URL.createObjectURL(selectedFile);
    setPreview(objectUrl);

    // free memory when ever this component is unmounted
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile]);

  const onSelectFile = (e) => {
    if (!!errorMessage) {
      setErrorMessage(e.target.validationMessage);
    }
    if (!e.target.files || e.target.files.length === 0) {
      return;
    }

    // I've kept this example simple by using the first image instead of multiple
    setSelectedFile(e.target.files[0]);
    if (onChange) {
      onChange(e.target.files[0]);
    }
  };

  return (
    <div
      {...getRootProps()}
      className={
        "input-image " +
        (selectedFile ? "" : "empty ") +
        (disabled ? "disabled" : "")
      }
    >
      <label className="label">
        {label} {required && label ? "*" : ""}
      </label>
      <div
        className="upload-image vertical"
        onClick={() => ref.current.click()}
      >
        {selectedFile && (
          <div className="upload-image-wrapper">
            <DashboardImage src={preview} height="100px" width="100px" noOpen />
            {!disabled && (
              <div
                className="close-icon"
                onClick={(e) => {
                  e.preventDefault();
                  setSelectedFile(undefined);
                }}
              >
                <svg
                  width="9"
                  height="9"
                  viewBox="0 0 9 9"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M5.4125 4.5L8.875 7.9625V8.875H7.9625L4.5 5.4125L1.0375 8.875H0.125V7.9625L3.5875 4.5L0.125 1.0375V0.125H1.0375L4.5 3.5875L7.9625 0.125H8.875V1.0375L5.4125 4.5Z"
                    fill="white"
                  />
                </svg>
              </div>
            )}
          </div>
        )}
        {!selectedFile ? <div className="intruction">Choose Image</div> : null}
      </div>
      <input
        {...getInputProps()}
        id={label}
        type="file"
        ref={ref}
        required={required}
        autoComplete="on"
        onChange={onSelectFile}
        disabled={selectedFile || disabled}
        onInvalid={(e) => {
          e.preventDefault();
          setErrorMessage(e.target.validationMessage);
        }}
        accept="image/png, image/gif, image/jpeg"
      />
      {!!errorMessage && <div className="error-message">{errorMessage}</div>}
    </div>
  );
};

export function useOutsideClick(ref, func) {
  useEffect(() => {
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, func]);
}

export function useOutsideScroll(ref, func) {
  useEffect(() => {
    function handleScrollOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        func();
      }
    }
    window.addEventListener("scroll", handleScrollOutside, true);
    return () => {
      window.removeEventListener("scroll", handleScrollOutside, true);
    };
  }, [ref, func]);
}
