import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  comment_url,
  delivery_boy_list_url,
  seller_url,
  tagon_customer_url,
  ticket_url,
} from "../../api/urls";
import { useFetch } from "../../utils/useFetch";
import Loading from "../../components/Loading/Loading";
import BackButton from "../../components/BackButton/BackButton";
import "./view_ticket.scss";
import "./manage_tickets.scss";
import {
  InputDate,
  InputText,
  InputTextBox,
} from "../../components/Inputs/Inputs";
import { moveUpToError } from "../../utils/moveUpToError";
import { useState, useRef, useEffect } from "react";
import { api } from "../../api/api";
import unknownError from "../../utils/unknownError";
import { useModal } from "../../components/Modaler";
import moment from "moment";
import { MessageSquareMore, Trash2, Mail, Reply } from "lucide-react";
import {
  typeOfTicketEnums,
  priorityEnums,
  statusEnums,
} from "./manage_tickets";
import {
  Keywords,
  keywordsToString,
  stringToKeyword,
} from "../../components/Keywords/Keywords";
import { APIDropdown } from "../../components/APIDropdown/APIDropdown";
import {
  department_url,
  internal_users_url,
  subject_url,
} from "../../api/urls";
import { userTypeEnums } from "./new_ticket";
import { DashboardImage } from "../../components/DashboardImage/DashboardImage";
import {
  EditorInput,
  editorToText,
  textToEditor,
} from "../../components/EditorInput/editorInput";

export default function ViewTicket() {
  const backUrl = "/manage_tickets";
  const params = useParams();
  const commentsData = useFetch(comment_url + `?ticket=${params.id}`);
  const ticketData = useFetch(ticket_url + params.id);
  const modaler = useModal();
  const tagonCustomerData = useFetch(tagon_customer_url + "?feilds=");
  const sellerData = useFetch(seller_url);
  const deliveryBoyData = useFetch(delivery_boy_list_url);
  const subjectData = useFetch(subject_url);
  const departmentData = useFetch(department_url);
  const assigneeData = useFetch(
    ticketData?.data?.department
      ? internal_users_url + `?dept=${ticketData.data.department}`
      : null
  );

  const [commentAnchor, setCommentAnchor] = useState(null);
  const [replyAnchor, setReplyAnchor] = useState(null);
  const [replyToComment, setReplyToComment] = useState(null);

  if (commentsData.loading || ticketData.loading) {
    return <Loading />;
  }

  const handleEditNote = () => {
    modaler.opener(
      <EditNoteModal
        notes={ticketData?.data?.notes}
        refetch={ticketData.refetch}
        id={params.id}
      />,
      true
    );
  };

  const handleEditCustomFields = () => {
    modaler.opener(
      <CustomFieldsModal
        customFields={ticketData?.data?.custom_fields}
        refetch={ticketData.refetch}
        id={params.id}
      />,
      true
    );
  };

  const handleEditTicket = () => {
    modaler.opener(
      <EditTicketModal
        ticket={ticketData?.data}
        refetch={ticketData.refetch}
        id={params.id}
      />,
      true
    );
  };

  const getPrintableName = (id, enumArray) => {
    const item = enumArray.find((item) => item.id === id);
    return item?.printable_name || id;
  };

  const getConcernedPersonName = () => {
    if (!ticketData?.data?.user_uniqueid) return "Not assigned";

    switch (ticketData?.data?.user_type) {
      case "tagon app": {
        const user = tagonCustomerData?.data?.find(
          (user) =>
            user.id.toString() === ticketData?.data?.user_uniqueid.toString()
        );
        return user?.mobile_number || ticketData?.data?.user_uniqueid;
      }
      case "Seller app": {
        const user = sellerData?.data?.find(
          (user) =>
            user.id.toString() === ticketData?.data?.user_uniqueid.toString()
        );
        return user?.name || ticketData?.data?.user_uniqueid;
      }
      case "Delivery app": {
        const user = deliveryBoyData?.data?.find(
          (user) =>
            user.id.toString() === ticketData?.data?.user_uniqueid.toString()
        );
        return user?.mobile_no || ticketData?.data?.user_uniqueid;
      }
      default:
        return "...";
    }
  };

  const getSubjectName = () => {
    const subject = subjectData?.data?.find(
      (subject) =>
        subject.id.toString() === ticketData?.data?.subject?.toString()
    );
    return subject?.printable_name || "...";
  };

  const getDepartmentName = () => {
    const department = departmentData?.data?.find(
      (dept) => dept.id.toString() === ticketData?.data?.department?.toString()
    );
    return department?.printable_name || "...";
  };

  const getAssigneeName = () => {
    const assignee = assigneeData?.data?.find(
      (user) =>
        user.id.toString() === ticketData?.data?.assigned_uniqueid?.toString()
    );
    return assignee?.username || "...";
  };

  const renderComments = (comments, parentId = null) => {
    return comments
      .filter((comment) => comment.parent === parentId)
      .map((comment) => {
        const replies = comments.filter((c) => c.parent === comment.id);

        return (
          <div key={comment.id}>
            <div className="comment">
              <button
                className="reply-btn"
                onClick={(e) => {
                  setReplyAnchor(e.currentTarget);
                  setReplyToComment(comment);
                }}
              >
                <Reply size={16} />
              </button>
              <div className="comment-header">
                <Avatar name={comment.user_name} />
                <div className="comment-header-text">
                  <div>
                    <span className="comment-header-name">
                      {comment.user_name}
                    </span>{" "}
                    {comment.send_email ? "Mailed" : "Commented"}
                  </div>
                  <div className="comment-header-date">
                    {moment(comment.created_at).format("lll")}
                  </div>
                </div>
              </div>
              <div style={{ padding: "5px" }} />
              <div className="comment-text-wrapper">
                {comment.send_email ? (
                  <Mail className="comment-icon" />
                ) : (
                  <MessageSquareMore className="comment-icon" />
                )}
                <div
                  className="comment-text"
                  dangerouslySetInnerHTML={{ __html: comment.comment }}
                />
              </div>

              {replies.length > 0 && (
                <details className="replies-accordion">
                  <summary>{replies.length} replies</summary>
                  <div className="replies-container">
                    {renderComments(comments, comment.id)}
                  </div>
                </details>
              )}
            </div>
          </div>
        );
      });
  };

  return (
    <div className="view-ticket">
      <BackButton href={backUrl} />
      <div style={{ padding: "10px" }} />
      <div className="view-ticket-layout">
        <div className="view-ticket-main-section">
          <div className="comment-btn-wrapper">
            <button
              className="btn comment-btn"
              onClick={(e) => setCommentAnchor(e.currentTarget)}
            >
              <MessageSquareMore className="comment-btn-icon" />
              Add comment
            </button>
            <button
              className="btn comment-btn"
              onClick={() =>
                modaler.opener(
                  <EmailModal
                    refetch={commentsData.refetch}
                    id={params.id}
                    customerEmail={ticketData?.data?.email}
                  />,
                  true
                )
              }
            >
              <Mail className="comment-btn-icon" />
              Send Email
            </button>
          </div>
          <div style={{ padding: "10px" }} />
          <div className="horizontal-divider" />
          <div className="ticket-card-small">
            <div className="ticket-row-one">
              <Mail className="ticket-row-one-icon" />
              {ticketData?.data?.type_of_ticket}
            </div>
            <div style={{ padding: "2px" }} />
            <div className="ticket-row-two">
              <DashboardImage
                height="40px"
                width="40px"
                circle
                src={ticketData?.data?.image}
              />
              <div className="ticket-row-two-info">
                <div className="ticket-row-two-info-title">
                  <div className="ticket-row-two-info-title-name">
                    {ticketData?.data?.full_name}
                  </div>
                  <div className="ticket-row-two-info-title-report">
                    Report via {ticketData?.data?.user_type}
                  </div>
                </div>
                <div className="ticket-row-two-info-date">
                  {moment(ticketData?.data?.created_at).format("lll")}
                </div>
              </div>
            </div>
            <div className="ticket-row-three">
              <Mail className="ticket-row-one-icon" />
              {ticketData?.data?.description}
            </div>
          </div>
          <div style={{ padding: "10px" }} />
          <div style={{ padding: "10px" }} />
          <div className="comments">
            {commentsData.data?.length > 0 ? (
              renderComments(commentsData.data)
            ) : (
              <div className="no-comments">No comments added</div>
            )}
          </div>
        </div>
        <div className="vertical-divider" />
        <div className="view-ticket-sidebar">
          <div className="sidebar-section">
            <div className="sidebar-section-header">
              <h3>Note</h3>
              <div className="blue-link" onClick={handleEditNote}>
                {ticketData?.data?.notes ? "Edit" : "Add"}
              </div>
            </div>
            <div className="notes-content">
              <p>{ticketData?.data?.notes || "No note added"}</p>
            </div>
          </div>
          <div className="horizontal-divider" />
          <div className="sidebar-section">
            <div className="sidebar-section-header">
              <h3>Properties</h3>
              <div className="blue-link" onClick={handleEditTicket}>
                Edit
              </div>
            </div>
            <div className="ticket-details-content">
              <div>
                <strong>User Type:</strong>{" "}
                {getPrintableName(ticketData?.data?.user_type, userTypeEnums)}
              </div>
              <div>
                <strong>Concerned Person:</strong> {getConcernedPersonName()}
              </div>
              <div>
                <strong>Subject:</strong> {getSubjectName()}
              </div>
              <div>
                <strong>Type of Ticket:</strong>{" "}
                {getPrintableName(
                  ticketData?.data?.type_of_ticket,
                  typeOfTicketEnums
                )}
              </div>
              <div>
                <strong>Deadline:</strong>{" "}
                {ticketData?.data?.deadline
                  ? moment(ticketData.data.deadline).format("DD-MM-YYYY")
                  : "Not set"}
              </div>
              <div>
                <strong>Department:</strong> {getDepartmentName()}
              </div>
              <div>
                <strong>Assignee:</strong> {getAssigneeName()}
              </div>
              <div>
                <strong>Priority:</strong>{" "}
                {getPrintableName(ticketData?.data?.priority, priorityEnums)}
              </div>
              <div>
                <strong>Status:</strong>{" "}
                {getPrintableName(ticketData?.data?.status, statusEnums)}
              </div>
              <div>
                <strong>Tags:</strong> {ticketData?.data?.tags}
              </div>
            </div>
          </div>
          <div className="horizontal-divider" />
          <div className="sidebar-section">
            <div className="sidebar-section-header">
              <h3>Custom Fields</h3>
              <div className="blue-link" onClick={handleEditCustomFields}>
                {ticketData?.data?.custom_fields ? "Edit" : "Add"}
              </div>
            </div>
            <div className="custom-fields-content">
              {ticketData?.data?.custom_fields?.length > 0 ? (
                ticketData.data.custom_fields.map((field) => (
                  <div key={field.id}>
                    <strong>{field.field_name}:</strong>{" "}
                    {field.field_type === "date picker"
                      ? moment(field.field_value).format("DD-MM-YYYY")
                      : field.field_value}
                  </div>
                ))
              ) : (
                <p>No custom fields added</p>
              )}
            </div>
          </div>
          <div className="horizontal-divider" />
        </div>
      </div>

      {commentAnchor && (
        <CommentPopover
          refetch={commentsData.refetch}
          id={params.id}
          anchorEl={commentAnchor}
          onClose={() => setCommentAnchor(null)}
        />
      )}

      {replyAnchor && (
        <CommentPopover
          refetch={commentsData.refetch}
          id={params.id}
          parentComment={replyToComment}
          anchorEl={replyAnchor}
          onClose={() => {
            setReplyAnchor(null);
            setReplyToComment(null);
          }}
          isReply={true}
        />
      )}
    </div>
  );
}

function Avatar({ name }) {
  return <div className="avatar">{name[0]?.toUpperCase()}</div>;
}

function EditNoteModal({ notes, refetch, id }) {
  const [data, setData] = useState({ notes });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const modaler = useModal();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    api
      .patch(ticket_url + id + "/", data)
      .then(() => {
        refetch();
        modaler.closer();
      })
      .catch((err) => {
        if (err.response.data) {
          setErrors(err.response.data);
          moveUpToError();
        } else {
          unknownError(err);
        }
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div style={{ padding: "20px", width: "400px" }}>
      <h1>{notes ? "Edit Note" : "Add Note"}</h1>
      <form onSubmit={handleSubmit}>
        <InputTextBox
          label="Note"
          placeholder="Enter your note"
          value={data.notes}
          error={errors["notes"]}
          required
          onChange={(v) => setData({ ...data, notes: v })}
        />
        <div style={{ padding: "10px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn" type="submit" disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

function CustomFieldsModal({ customFields, refetch, id }) {
  const [data, setData] = useState({
    custom_fields: customFields || [],
  });
  const [isSaving, setIsSaving] = useState(false);
  const modaler = useModal();

  const addCustomField = (newField) => {
    setData((prev) => ({
      custom_fields: [...prev.custom_fields, newField],
    }));
  };

  const deleteCustomField = (index) => {
    const newFields = data.custom_fields.filter((_, i) => i !== index);
    setData({ custom_fields: newFields });
  };

  const updateCustomField = (index, key, value) => {
    const newFields = [...data.custom_fields];
    newFields[index][key] = value;
    setData({ custom_fields: newFields });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    api
      .patch(ticket_url + id + "/", data)
      .then(() => {
        refetch();
        modaler.closer();
      })
      .catch((err) => {
        unknownError(err);
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "500px",
        maxHeight: "80vh",
        overflow: "auto",
        maxWidth: "100%",
      }}
    >
      <h1>
        {customFields?.length ? "Edit Custom Fields" : "Add Custom Fields"}
      </h1>
      <form onSubmit={handleSubmit}>
        <div className="custom-fields">
          {data.custom_fields.map((field, index) => (
            <CustomField
              key={index}
              field={field}
              index={index}
              onDelete={deleteCustomField}
              onChange={updateCustomField}
              view={false}
            />
          ))}
        </div>
        <CustomFieldDropdown onAddField={addCustomField} />
        <div style={{ padding: "10px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn" type="submit" disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

function CustomField({ field, index, onDelete, onChange, view }) {
  return (
    <div className="custom-field">
      {!view && (
        <button
          type="button"
          className="delete-custom-field"
          onClick={() => onDelete(index)}
        >
          <Trash2 />
        </button>
      )}
      <div className="custom-field-header">
        <InputText
          label="Label"
          placeholder="Label"
          disabled={view}
          value={field.field_name}
          required
          onChange={(v) => onChange(index, "field_name", v)}
        />
      </div>
      {field.field_type === "date picker" ? (
        <InputDate
          label="Value"
          placeholder="Value"
          disabled={view}
          value={field.field_value}
          required
          onChange={(v) => onChange(index, "field_value", v)}
        />
      ) : (
        <InputText
          label="Value"
          placeholder="Value"
          disabled={view}
          value={field.field_value}
          required
          onChange={(v) => onChange(index, "field_value", v)}
        />
      )}
    </div>
  );
}

function CustomFieldDropdown({ onAddField }) {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addField = (fieldType) => {
    onAddField({
      field_name: "",
      field_value: "",
      field_type: fieldType,
    });
    setShowOptions(false);
  };

  return (
    <div className="custom-field-add" ref={dropdownRef}>
      <span
        className="add-custom-field-link"
        onClick={() => setShowOptions(!showOptions)}
      >
        Add custom field
      </span>
      {showOptions && (
        <div className="custom-field-options">
          <button
            className="custom-field-option"
            onClick={() => addField("string")}
          >
            String field
          </button>
          <button
            className="custom-field-option"
            onClick={() => addField("date picker")}
          >
            Date field
          </button>
          <button
            className="custom-field-option"
            onClick={() => addField("number")}
          >
            Number field
          </button>
        </div>
      )}
    </div>
  );
}

function EditTicketModal({ ticket, refetch, id }) {
  const [data, setData] = useState({
    user_type: ticket?.user_type || "",
    user_uniqueid: ticket?.user_uniqueid || "",
    customer_name: ticket?.customer_name || "",
    mobile_number: ticket?.mobile_number || "",
    deadline: ticket?.deadline || null,
    assigned_type: ticket?.assigned_type || "",
    assigned_uniqueid: ticket?.assigned_uniqueid || "",
    brand_name: ticket?.brand_name || "",
    category1: ticket?.category1 || "",
    category2: ticket?.category2 || "",
    category3: ticket?.category3 || "",
    order_id: ticket?.order_id || "",
    products: ticket?.products || "",
    seller: ticket?.seller || "",
    Delivery_partner: ticket?.Delivery_partner || "",
    AWB_number: ticket?.AWB_number || "",
    notes: ticket?.notes || "",
    description: ticket?.description || "",
    tags: ticket?.tags || "",
    email: ticket?.email || "",
    subject: ticket?.subject || null,
    type_of_ticket: ticket?.type_of_ticket || null,
    status: ticket?.status || "OPEN",
    priority: ticket?.priority || null,
    department: ticket?.department || null,
    source: ticket?.source || null,
  });
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const modaler = useModal();

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    api
      .patch(ticket_url + id + "/", data)
      .then(() => {
        refetch();
        modaler.closer();
      })
      .catch((err) => {
        if (err.response.data) {
          setErrors(err.response.data);
          moveUpToError();
        } else {
          unknownError(err);
        }
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "500px",
        maxHeight: "80vh",
        overflow: "auto",
      }}
    >
      <h1>Edit Ticket</h1>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <APIDropdown
          apiData={{ data: userTypeEnums }}
          label="User Type"
          value={data.user_type}
          error={errors["user_type"]}
          required
          onChange={(v) => setData({ ...data, user_type: v })}
        />
        <APIDropdown
          url={subject_url}
          label="Subject"
          value={data.subject}
          error={errors["subject"]}
          required
          onChange={(v) => setData({ ...data, subject: v })}
        />
        <APIDropdown
          apiData={{ data: typeOfTicketEnums }}
          label="Type of Ticket"
          value={data.type_of_ticket}
          error={errors["type_of_ticket"]}
          required
          onChange={(v) => setData({ ...data, type_of_ticket: v })}
        />
        <InputTextBox
          label="Description"
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => setData({ ...data, description: v })}
        />
        <InputDate
          label="Deadline"
          value={data.deadline}
          error={errors["deadline"]}
          required
          onChange={(v) => setData({ ...data, deadline: v })}
        />
        <APIDropdown
          url={department_url}
          label="Department"
          value={data.department}
          error={errors["department"]}
          required
          onChange={(v) => setData({ ...data, department: v })}
        />
        {data.department && (
          <APIDropdown
            url={internal_users_url + `?dept=${data.department}`}
            parameterForGet="username"
            label="Assignee"
            value={data.assigned_uniqueid}
            error={errors["assigned_uniqueid"]}
            required
            onChange={(v) => setData({ ...data, assigned_uniqueid: v })}
          />
        )}
        <APIDropdown
          apiData={{ data: priorityEnums }}
          label="Priority"
          value={data.priority}
          error={errors["priority"]}
          required
          onChange={(v) => setData({ ...data, priority: v })}
        />
        <APIDropdown
          apiData={{ data: statusEnums }}
          label="Status"
          value={data.status}
          error={errors["status"]}
          required
          onChange={(v) => setData({ ...data, status: v })}
        />
        <Keywords
          label="Tags"
          value={stringToKeyword(data.tags)}
          error={errors["tags"]}
          onChange={(v) => setData({ ...data, tags: keywordsToString(v) })}
        />
        <div style={{ padding: "10px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn" type="submit" disabled={isSaving}>
            {isSaving ? "Saving..." : "Save"}
          </button>
        </div>
      </form>
    </div>
  );
}

function EmailModal({ refetch, id, customerEmail }) {
  const initialState = {
    subject_of_email: "",
    comment: textToEditor(""),
    send_email: true,
    to: customerEmail,
  };

  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const modaler = useModal();

  const set = (key, value) => {
    setData((d) => ({ ...d, [key]: value }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSaving(true);
    const newData = { ...data, ticket: id };
    newData.comment = editorToText(newData.comment);
    api
      .post(comment_url, newData)
      .then(() => {
        refetch();
        modaler.closer();
        setData(initialState);
      })
      .catch((err) => {
        if (err.response.data) {
          setErrors(err.response.data);
          moveUpToError();
        } else {
          unknownError(err);
        }
      })
      .finally(() => setIsSaving(false));
  };

  return (
    <div
      style={{
        padding: "20px",
        width: "600px",
        maxHeight: "80vh",
        overflow: "auto",
        maxWidth: "100%",
      }}
    >
      <h1>Send Email</h1>
      <form
        onSubmit={handleSubmit}
        style={{ display: "flex", flexDirection: "column", gap: "10px" }}
      >
        <InputText label="To" value={data.to} disabled />
        <InputText
          label="Subject"
          placeholder="Enter your subject"
          value={data.subject_of_email}
          error={errors["subject_of_email"]}
          required
          onChange={(v) => set("subject_of_email", v)}
        />
        <EditorInput
          label="Message"
          placeholder="Enter your message"
          value={data.comment}
          error={errors["comment"]}
          required
          onChange={(v) => set("comment", v)}
        />
        <div style={{ padding: "10px" }} />
        <div style={{ display: "flex", justifyContent: "flex-end" }}>
          <button className="btn" type="submit" disabled={isSaving}>
            {isSaving ? "Sending..." : "Send"}
          </button>
        </div>
      </form>
    </div>
  );
}

function CommentPopover({
  refetch,
  id,
  parentComment = null,
  anchorEl,
  onClose,
  isReply = false,
}) {
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const inputRef = useRef(null);
  const popoverRef = useRef(null);

  useEffect(() => {
    inputRef.current?.focus();

    const handleClickOutside = (event) => {
      if (
        popoverRef.current &&
        !popoverRef.current.contains(event.target) &&
        !anchorEl.contains(event.target)
      ) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [anchorEl, onClose]);

  useEffect(() => {
    if (anchorEl && popoverRef.current) {
      const rect = anchorEl.getBoundingClientRect();
      popoverRef.current.style.position = "fixed";
      popoverRef.current.style.top = `${rect.bottom + 5}px`;
      if (isReply) {
        popoverRef.current.style.left = `${rect.left - 100}px`;
      } else {
        popoverRef.current.style.left = `${rect.left}px`;
      }
    }
  }, [anchorEl, isReply]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!comment.trim()) return;

    setIsSaving(true);
    const newData = {
      comment,
      parent: parentComment?.id || null,
      ticket: id,
    };

    api
      .post(comment_url, newData)
      .then(() => {
        refetch();
        onClose();
      })
      .catch((err) => unknownError(err))
      .finally(() => setIsSaving(false));
  };

  return (
    <div className="comment-popover" ref={popoverRef}>
      <form onSubmit={handleSubmit} className="comment-form">
        <input
          ref={inputRef}
          type="text"
          value={comment}
          onChange={(e) => setComment(e.target.value)}
          placeholder={
            parentComment ? "Write a reply..." : "Write a comment..."
          }
          className="comment-input"
        />
        <button
          type="submit"
          className="comment-submit"
          disabled={isSaving || !comment.trim()}
        >
          {isSaving ? "..." : "Send"}
        </button>
      </form>
    </div>
  );
}
