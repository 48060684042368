import { Header } from "semantic-ui-react";
import { useHistory, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";

import {
  InputDate,
  InputText,
  InputTextBox,
} from "../../components/Inputs/Inputs";
import BackButton from "../../components/BackButton/BackButton";
import { api } from "../../api/api";
import { useToast } from "../../components/Toast";
import {
  delivery_boy_list_url,
  department_url,
  internal_users_url,
  seller_url,
  subject_url,
  tagon_customer_url,
  ticket_url,
} from "../../api/urls";
import unknownError from "../../utils/unknownError";
import Loading from "../../components/Loading/Loading";
import { moveUpToError } from "../../utils/moveUpToError";
import { modules } from "../../api/codes";
import {
  Keywords,
  keywordsToString,
  stringToKeyword,
} from "../../components/Keywords/Keywords";
import { APIDropdown } from "../../components/APIDropdown/APIDropdown";
import "./new_ticket.scss";
import { Trash2 } from "lucide-react";
import {
  priorityEnums,
  statusEnums,
  typeOfTicketEnums,
} from "./manage_tickets";

const initialState = {
  comments: [],
  custom_fields: [],
  user_type: "",
  user_uniqueid: "",
  customer_name: "",
  mobile_number: "",
  deadline: null,
  assigned_type: "",
  assigned_uniqueid: "",
  brand_name: "",
  category1: "",
  category2: "",
  category3: "",
  order_id: "",
  products: "",
  seller: "",
  Delivery_partner: "",
  AWB_number: "",
  notes: "",
  description: "",
  tags: "",
  email: "",
  upload_file: "",
  subject: null,
  type_of_ticket: null,
  status: "OPEN",
  priority: null,
  department: null,
  source: null,
};

const module = modules.ManageTickets;

const title = "New Ticket";
const backUrl = "/manage_tickets";
const apiUrl = ticket_url;

const CustomFieldDropdown = ({ onAddField }) => {
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const addField = (fieldType) => {
    onAddField({
      field_name: "",
      field_value: "",
      field_type: fieldType,
    });
    setShowOptions(false);
  };

  return (
    <div className="custom-field-add" ref={dropdownRef}>
      <span
        className="add-custom-field-link"
        onClick={() => setShowOptions(!showOptions)}
      >
        Add custom field
      </span>
      {showOptions && (
        <div className="custom-field-options">
          <button
            className="custom-field-option"
            onClick={() => addField("string")}
          >
            String field
          </button>
          <button
            className="custom-field-option"
            onClick={() => addField("date picker")}
          >
            Date field
          </button>
          <button
            className="custom-field-option"
            onClick={() => addField("number")}
          >
            Number field
          </button>
        </div>
      )}
    </div>
  );
};

const CustomField = ({ field, index, onDelete, onChange, view }) => {
  return (
    <div className="custom-field">
      {!view && (
        <button
          type="button"
          className="delete-custom-field"
          onClick={() => onDelete(index)}
        >
          <Trash2 />
        </button>
      )}
      <div className="custom-field-header">
        <InputText
          label="Label"
          placeholder="Label"
          disabled={view}
          value={field.field_name}
          required
          onChange={(v) => onChange(index, "field_name", v)}
        />
      </div>
      {field.field_type === "date picker" ? (
        <InputDate
          label="Value"
          placeholder="Value"
          disabled={view}
          value={field.field_value}
          required
          onChange={(v) => onChange(index, "field_value", v)}
        />
      ) : (
        <InputText
          label="Value"
          placeholder="Value"
          disabled={view}
          value={field.field_value}
          required
          onChange={(v) => onChange(index, "field_value", v)}
        />
      )}
    </div>
  );
};

export const userTypeEnums = [
  {
    id: "tagon app",
    printable_name: "TagOn App",
  },
  {
    id: "Seller app",
    printable_name: "Seller App",
  },
  {
    id: "Delivery app",
    printable_name: "Delivery App",
  },
];

const NewTicket = ({ edit, view }) => {
  const [data, setData] = useState(initialState);
  const [errors, setErrors] = useState({});
  const history = useHistory();
  const params = useParams();
  const toast = useToast();
  const [isGetting, setIsGetting] = useState(true);
  const [isSaving, setIsSaving] = useState(false);

  const set = (key, value) => {
    setData((d) => {
      const newData = { ...d };
      newData[key] = value;
      return newData;
    });
  };

  useEffect(() => {
    if (edit || view || params.id) {
      const getData = async () => {
        try {
          const res = await api.get(apiUrl + params.id + "/", {
            headers: {
              module: module,
            },
          });
          setData(res.data);
          setIsGetting(false);
        } catch (err) {
          unknownError(err);
          setIsGetting(false);
        }
      };

      getData();
    } else {
      setIsGetting(false);
    }
  }, [params.id, edit, view]);

  if (isGetting) return <Loading />;

  const addCustomField = (newField) => {
    set("custom_fields", [...data.custom_fields, newField]);
  };

  const deleteCustomField = (index) => {
    const newFields = data.custom_fields.filter((_, i) => i !== index);
    set("custom_fields", newFields);
  };

  const updateCustomField = (index, key, value) => {
    const newFields = [...data.custom_fields];
    newFields[index][key] = value;
    set("custom_fields", newFields);
  };

  return (
    <form
      id="form-wrapper"
      onSubmit={(e) => {
        e.preventDefault();
        setIsSaving(true);
        try {
          if (edit && params.id) {
            api
              .patch(apiUrl + data.id + "/", data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Edited data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          } else {
            api
              .post(apiUrl, data, {
                headers: {
                  "Content-Type": "application/json",
                  module: module,
                },
              })
              .then((res) => {
                toast.open("Added data with id: " + res.data.id, "success");
                history.push(backUrl);
                setIsSaving(false);
              })
              .catch((err) => {
                if (err.response.data) {
                  setErrors(err.response.data);
                  moveUpToError();
                } else {
                  unknownError(err);
                }
                setIsSaving(false);
              });
          }
        } catch (err) {
          unknownError(err);
          setIsSaving(false);
        }
      }}
    >
      <div id="form">
        <BackButton href={backUrl} />
        <Header>{title}</Header>
        <APIDropdown
          apiData={{ data: userTypeEnums }}
          label="User Type"
          placeholder="User Type"
          disabled={view}
          value={data.user_type}
          error={errors["user_type"]}
          required
          onChange={(v) => set("user_type", v)}
        />
        {data.user_type === "tagon app" && (
          <APIDropdown
            url={tagon_customer_url + "?feilds="}
            label="Concerned Person"
            placeholder="Concerned Person"
            disabled={view}
            value={data.user_uniqueid}
            error={errors["user_uniqueid"]}
            parameterForGet="mobile_number"
            required
            onChange={(v) => set("user_uniqueid", v)}
          />
        )}
        {data.user_type === "Seller app" && (
          <APIDropdown
            url={seller_url}
            label="Concerned Person"
            placeholder="Concerned Person"
            disabled={view}
            value={data.user_uniqueid}
            error={errors["user_uniqueid"]}
            required
            onChange={(v) => set("user_uniqueid", v)}
          />
        )}
        {data.user_type === "Delivery app" && (
          <APIDropdown
            url={delivery_boy_list_url}
            parameterForGet="mobile_no"
            label="Concerned Person"
            placeholder="Concerned Person"
            disabled={view}
            value={data.user_uniqueid}
            error={errors["user_uniqueid"]}
            required
            onChange={(v) => set("user_uniqueid", v)}
          />
        )}
        <APIDropdown
          url={subject_url}
          label="Subject"
          placeholder="Subject"
          disabled={view}
          value={data.subject}
          error={errors["subject"]}
          required
          onChange={(v) => set("subject", v)}
        />
        <APIDropdown
          apiData={{ data: typeOfTicketEnums }}
          label="Type of Ticket"
          placeholder="Type of Ticket"
          disabled={view}
          value={data.type_of_ticket}
          error={errors["type_of_ticket"]}
          required
          onChange={(v) => set("type_of_ticket", v)}
        />
        <InputTextBox
          label="Description"
          placeholder="Description"
          disabled={view}
          value={data.description}
          error={errors["description"]}
          required
          onChange={(v) => set("description", v)}
        />
        <InputDate
          label="Deadline"
          placeholder="Deadline"
          disabled={view}
          value={data.deadline}
          error={errors["deadline"]}
          required
          onChange={(v) => set("deadline", v)}
        />
        <APIDropdown
          url={department_url}
          label="Department"
          placeholder="Department"
          disabled={view}
          value={data.department}
          error={errors["department"]}
          required
          onChange={(v) => set("department", v)}
        />
        {data.department && (
          <APIDropdown
            url={internal_users_url + `?dept=${data.department}`}
            parameterForGet="username"
            label="Assignee"
            placeholder="Assignee"
            disabled={view}
            value={data.assigned_uniqueid}
            error={errors["assigned_uniqueid"]}
            required
            onChange={(v) => set("assigned_uniqueid", v)}
          />
        )}
        <APIDropdown
          apiData={{ data: priorityEnums }}
          label="Priority"
          placeholder="Priority"
          disabled={view}
          value={data.priority}
          error={errors["priority"]}
          required
          onChange={(v) => set("priority", v)}
        />
        <APIDropdown
          apiData={{ data: statusEnums }}
          label="Status"
          placeholder="Status"
          disabled={view}
          value={data.status}
          error={errors["status"]}
          required
          onChange={(v) => set("status", v)}
        />
        <Keywords
          label="Tags"
          placeholder="Tags"
          disabled={view}
          value={stringToKeyword(data.tags)}
          error={errors["tags"]}
          onChange={(v) => set("tags", keywordsToString(v))}
        />
        <div className="custom-fields">
          {data.custom_fields.map((field, index) => (
            <CustomField
              key={index}
              field={field}
              index={index}
              onDelete={deleteCustomField}
              onChange={updateCustomField}
              view={view}
            />
          ))}
        </div>
        {!view && <CustomFieldDropdown onAddField={addCustomField} />}
        <div style={{ padding: "10px" }} />
        {!view && (
          <>
            <div style={{ padding: "10px" }} />
            <div className="actions">
              <button
                className="btn-red"
                type="button"
                onClick={() => {
                  history.push(backUrl);
                }}
              >
                Cancel
              </button>
              <button
                className="btn"
                onClick={() => {
                  moveUpToError();
                }}
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </>
        )}
        <div style={{ padding: "10px" }} />
      </div>
    </form>
  );
};

export default NewTicket;
